import { Button, CopyText } from "@dtpk-cc/components";
import React from "react";
import { trackClick } from "helpers/reactTracking";
import * as styles from "./young-tariff.module.scss";

type YoungTariffProps = {
  customClass?: string;
  isActive: boolean;
  onClick: () => void;
  trackingContext?: string;
};

const YoungTariff = ({
  isActive,
  onClick,
  customClass = "",
  trackingContext,
}: YoungTariffProps) => (
  <Button
    tabIndex={0}
    onClick={() => {
      onClick();
      trackClick("young-vorteil", trackingContext);
    }}
    variants={Button.Variant.bare}
    customClass={`${styles.youngTariff} ${customClass}  ${
      isActive ? styles.active : ""
    }`}
  >
    <CopyText variants={CopyText.Variant.highlight} customClass={styles.text}>
      Unter 28 Jahre
    </CopyText>
    <div
      className={`${styles.toggleButton} ${isActive ? styles.checked : ""} ${
        styles.toggleBtn
      }`}
    >
      <span className={styles.toggleButtonSwitcher} />
    </div>
  </Button>
);

export default YoungTariff;
